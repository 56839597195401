import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  FlattenedChefGroupBasketMaterialLimit,
  FlattenedChefGroupBasketMaterialLimitId,
} from "contexts/search/context";

export type ChefGroupBasketMaterialLimitsState = {
  slug: null | string;
  chefGroupBasketMaterialLimitSetId: number | null;
  chefGroupBasketMaterialLimits: Record<
    FlattenedChefGroupBasketMaterialLimitId,
    FlattenedChefGroupBasketMaterialLimit
  >;
};

const initialState: ChefGroupBasketMaterialLimitsState = {
  slug: null,
  chefGroupBasketMaterialLimitSetId: null,
  chefGroupBasketMaterialLimits: {},
};

export const chefGroupBasketMaterialLimitSetSlice = createSlice({
  name: "chefGroupBasketMaterialLimits",
  initialState,
  reducers: {
    dump(
      _,
      action: PayloadAction<
        [
          number,
          Record<
            FlattenedChefGroupBasketMaterialLimitId,
            FlattenedChefGroupBasketMaterialLimit
          >,
        ]
      >
    ): ChefGroupBasketMaterialLimitsState {
      const [chefGroupBasketMaterialLimitSetId, chefGroupBasketMaterialLimits] =
        action.payload;
      return {
        chefGroupBasketMaterialLimits,
        chefGroupBasketMaterialLimitSetId,
        slug: JSON.stringify(chefGroupBasketMaterialLimits),
      };
    },
    edit(
      state,
      {
        payload: chefGroupBasketMaterialLimit,
      }: PayloadAction<FlattenedChefGroupBasketMaterialLimit>
    ) {
      return {
        ...state,
        chefGroupBasketMaterialLimits: {
          ...state.chefGroupBasketMaterialLimits,
          [chefGroupBasketMaterialLimit.id]: {
            ...chefGroupBasketMaterialLimit,
          },
        },
      };
    },
  },
});
