import { FlattenedChefGroupBasketMaterialLimit } from "contexts/search/context";

export const groupBasketMaterialLimitsByBasket = (
  basketMaterialLimits: FlattenedChefGroupBasketMaterialLimit[]
) => {
  return basketMaterialLimits.reduce(
    (grouped, bound) => {
      if (!grouped[bound.basket_number]) {
        return {
          ...grouped,
          [bound.basket_number]: {
            basket_number: bound.basket_number,
            bounds: [bound],
          },
        };
      } else {
        return {
          ...grouped,
          [bound.basket_number]: {
            ...grouped[bound.basket_number]!,
            bounds: [...grouped[bound.basket_number]!.bounds, bound],
          },
        };
      }
    },
    {} as Record<
      number,
      {
        basket_number: number;
        bounds: FlattenedChefGroupBasketMaterialLimit[];
      }
    >
  );
};
