import { StoreEnhancerStoreCreator, configureStore } from "@reduxjs/toolkit";
import { backendApi } from "./api/enhancedApi";
import { settingsSlice } from "./slices/settings";
import * as Sentry from "@sentry/react";
import { mixMaterialLimitsSlice } from "./slices/mixMaterialLimits";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { chefGroupBasketMaterialLimitSetSlice } from "./slices/chefGroupBasketMaterialLimits";

const sentryReduxEnhancer = Sentry.createReduxEnhancer() as (
  next: StoreEnhancerStoreCreator
) => StoreEnhancerStoreCreator;

export const store = configureStore({
  reducer: {
    settings: settingsSlice.reducer,
    mixMaterialLimits: mixMaterialLimitsSlice.reducer,
    chefGroupBasketMaterialLimits: chefGroupBasketMaterialLimitSetSlice.reducer,
    [backendApi.reducerPath]: backendApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // When queries return files, we need to preprocess them into blobs
        // before they get stored.  Since blobs are not serialisable by default,
        // this means the store - and some actions - may contain non-serialisable
        // state.  If this becomes an issue, it's probably possible to convert
        // the content of the blob into a bytes object, but for now it's fine to
        // leave as-is.
        ignoredActions: ["api/executeQuery/fulfilled"],
        ignoredPaths: ["api.queries"],
      },
    }).concat(backendApi.middleware),
  enhancers: [sentryReduxEnhancer],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = useDispatch<AppDispatch>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
