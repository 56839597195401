import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { Chip, Tooltip } from "@mui/material";

import { SummaryTag } from "store/api/generatedApi";
import { useTenantTranslation } from "hooks/formatters";
import { getSummaryTagEnumTranslationKey } from "src/utils/getSummaryTagEnumTranslationKey";

export const PlanTagChip = ({ tag }: { tag: SummaryTag }) => {
  const { t } = useTenantTranslation();
  const tooltip = tag.description_key ? (
    <div>
      {t(tag.description_key)}
      <ul>
        {tag.details.map((detail) => (
          <li key={`${tag.tag}-${detail}`}>{t(detail)}</li>
        ))}
      </ul>
    </div>
  ) : null;
  return (
    <Chip
      sx={{
        "& .MuiChip-deleteIcon": {
          color: "action.active",
          "&:hover": {
            color: "action.active",
          },
        },
      }}
      label={t(getSummaryTagEnumTranslationKey(tag.tag))}
      size="small"
      onDelete={() => null}
      deleteIcon={
        tooltip ? (
          <Tooltip title={tooltip}>
            <InfoRoundedIcon />
          </Tooltip>
        ) : (
          <span />
        )
      }
    />
  );
};
