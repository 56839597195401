import React from "react";
import equal from "fast-deep-equal";
import { Stack, Typography } from "@mui/material";
import {
  BasketMaterialLimitId,
  FlattenedChefGroupBasketMaterialLimit,
  MixMaterialLimitId,
} from "contexts/search/context";
import { MaterialLimitChip } from "components/common/chips/materialLimit";
import { useTenantTranslation } from "hooks/formatters";
import {
  useChefGroupBasketMaterialLimits,
  useChefGroupBasketMaterialLimitsSlice,
} from "src/store/hooks/chefGroupBasketMaterialLimits";
import { typeSafeObjectEntries } from "src/utils/typeSafeObjectEntries";
import { typeSafeObjectFromEntries } from "src/utils/typeSafeObjectFromEntries";
import { BasketMaterialLimitEditorDialog } from "./basketMaterialLimitEditorDialog";
import { groupBasketMaterialLimitsByBasket } from "src/utils/groupBasketMaterialLimitsByBasket";
import {
  Id,
  MaterialLimit,
} from "components/common/forms/MaterialLimitsEditor";
import { Limits } from "src/utils/addNewLimit";
import {
  useChefGroupMixMaterialLimits,
  useMixMaterialLimitsSlice,
} from "src/store/hooks/mixMaterialLimits";

/**
 * Shows each of a series of chemistry constraints in small panels.
 */

export const BasketMaterialLimits = ({
  chefGroupId,
}: {
  chefGroupId: number;
}) => {
  const basketMaterialLimits = useChefGroupBasketMaterialLimits(chefGroupId);
  const mixMaterialLimits = useChefGroupMixMaterialLimits(chefGroupId);

  const { edit: editBasketMaterialLimit } =
    useChefGroupBasketMaterialLimitsSlice();
  const { edit: editMixMaterialLimit, add: addMixMaterialLimit } =
    useMixMaterialLimitsSlice();

  const { t } = useTenantTranslation();

  const [inEdit, setInEdit] = React.useState<
    | null
    | [MaterialLimit, BasketMaterialLimitId, string, MixMaterialLimitId | null]
  >(null);

  const handleSelect = (
    basketMaterialLimit: FlattenedChefGroupBasketMaterialLimit
  ) => {
    const baskets = typeSafeObjectFromEntries(
      basketMaterialLimits
        .filter(
          ({ basket_material_limit_id }) =>
            basket_material_limit_id ===
            basketMaterialLimit.basket_material_limit_id
        )
        .map((basketMaterialLimit) => {
          const {
            basket_id,
            basket_number,
            basket_volume,
            chef_group_basket_material_limit_id,
            min_mass,
            soft_min_mass,
            soft_max_mass,
            max_mass,
            id,
          } = basketMaterialLimit;
          return [
            basket_id as Id,
            {
              id,
              basketId: basket_id,
              number: basket_number,
              volume: basket_volume,
              chefGroupBasketMaterialLimitId:
                chef_group_basket_material_limit_id,
              limits: [
                min_mass,
                soft_min_mass,
                soft_max_mass,
                max_mass,
              ] as Limits,
            },
          ];
        })
    );

    const mix = mixMaterialLimits.find((mixMaterialLimit) =>
      equal(
        typeSafeObjectFromEntries(
          mixMaterialLimit.coefficients.map(
            ({ material_constraint_class_id, coefficient }) => [
              material_constraint_class_id,
              coefficient,
            ]
          )
        ),
        typeSafeObjectFromEntries(
          basketMaterialLimit.coefficients.map(
            ({ material_constraint_class_id, coefficient }) => [
              material_constraint_class_id,
              coefficient,
            ]
          )
        )
      )
    );

    const {
      name,
      hardness,
      coefficients,
      coefficients_signature,
      basket_material_limit_id,
    } = basketMaterialLimit;

    setInEdit([
      {
        name,
        baskets,
        mix: mix
          ? [mix.min_mass, mix.soft_min_mass, mix.soft_max_mass, mix.max_mass]
          : [null, null, null, null],
        hardness,
        coefficients: typeSafeObjectFromEntries(
          coefficients.map(({ material_constraint_class_id, coefficient }) => [
            material_constraint_class_id,
            coefficient,
          ])
        ),
      },
      basket_material_limit_id,
      coefficients_signature,
      mix ? mix.id : null,
    ]);
  };

  const handleSubmit = React.useCallback(
    (
      {
        name,
        hardness,
        coefficients: coefficients_,
        baskets,
        mix,
      }: MaterialLimit,
      basketMaterialLimitId: BasketMaterialLimitId,
      mixMaterialLimitId: null | MixMaterialLimitId,
      coefficientsSignature: string,
      chefGroupId: number
    ) => {
      const coefficients = typeSafeObjectEntries(coefficients_).map(
        ([material_constraint_class_id, coefficient]) => ({
          material_constraint_class_id,
          coefficient,
        })
      );
      if (mixMaterialLimitId !== null) {
        const [min_mass, soft_min_mass, soft_max_mass, max_mass] = mix;
        editMixMaterialLimit({
          id: mixMaterialLimitId,
          chef_group_id: chefGroupId,
          min_mass,
          max_mass,
          soft_min_mass,
          soft_max_mass,
          hardness,
          name,
          coefficients,
          coefficients_signature: coefficientsSignature,
        });
      } else if (mix.some((limit) => limit !== null)) {
        const [min_mass, soft_min_mass, soft_max_mass, max_mass] = mix;
        addMixMaterialLimit({
          chef_group_id: chefGroupId,
          min_mass,
          max_mass,
          soft_min_mass,
          soft_max_mass,
          hardness,
          name,
          coefficients,
        });
      }
      Object.values(baskets).forEach(
        ({
          id,
          basketId: basket_id,
          volume: basket_volume,
          number: basket_number,
          chefGroupBasketMaterialLimitId: chef_group_basket_material_limit_id,
          limits: [min_mass, soft_min_mass, soft_max_mass, max_mass],
        }) => {
          editBasketMaterialLimit({
            id,
            chef_group_id: chefGroupId,
            min_mass,
            max_mass,
            soft_min_mass,
            soft_max_mass,
            hardness: hardness,
            coefficients,
            name: name,
            coefficients_signature: coefficientsSignature,
            basket_id,
            basket_number,
            basket_volume,
            chef_group_basket_material_limit_id,
            basket_material_limit_id: basketMaterialLimitId,
          });
        }
      );
    },
    [editBasketMaterialLimit, editMixMaterialLimit, addMixMaterialLimit]
  );

  return (
    <Stack gap={2}>
      {Object.entries(
        groupBasketMaterialLimitsByBasket(basketMaterialLimits)
      ).map(([basketNumber, { bounds }]) => (
        <Stack gap={1} key={basketNumber}>
          <Typography>
            {t("basketNumber")} {basketNumber}
          </Typography>
          <Stack flexDirection="row" flexWrap="wrap">
            {bounds.map((bound) => (
              <MaterialLimitChip
                key={bound.basket_material_limit_id}
                minMass={bound.min_mass}
                maxMass={bound.max_mass}
                name={bound.name}
                onClick={() => handleSelect(bound)}
              />
            ))}
          </Stack>
        </Stack>
      ))}
      {inEdit ? (
        <BasketMaterialLimitEditorDialog
          doClose={() => setInEdit(null)}
          materialLimit={inEdit[0]}
          title={t("editMaterialLimit")}
          onSubmit={(materialLimit) => {
            const [
              ,
              basketMaterialLimitId,
              coefficientsSignature,
              mixMaterialLimitId,
            ] = inEdit;

            handleSubmit(
              materialLimit,
              basketMaterialLimitId,
              mixMaterialLimitId,
              coefficientsSignature,
              chefGroupId
            );
          }}
        />
      ) : null}
    </Stack>
  );
};
