import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { MixMaterialLimitId, MixMaterialLimit } from "contexts/search/context";
import { typeSafeObjectKeys } from "src/utils/typeSafeObjectKeys";
import { MaterialLimitCoefficientCreate } from "../api/generatedApi";

export type ReduxMixMaterialLimit = Omit<MixMaterialLimit, "coefficients"> & {
  coefficients: MaterialLimitCoefficientCreate[];
};

export type CreateReduxMixMaterialLimit = Omit<
  ReduxMixMaterialLimit,
  "id" | "coefficients_signature"
>;

export type MixMaterialLimitsState = {
  slug: null | string;
  mixMaterialLimitSetId: null | number;
  mixMaterialLimits: Record<MixMaterialLimitId, ReduxMixMaterialLimit>;
};

const initialState: MixMaterialLimitsState = {
  slug: null,
  mixMaterialLimitSetId: null,
  mixMaterialLimits: {},
};

export const mixMaterialLimitsSlice = createSlice({
  name: "mixMaterialLimits",
  initialState,
  reducers: {
    dump(
      _,
      action: PayloadAction<
        [number, Record<MixMaterialLimitId, ReduxMixMaterialLimit>]
      >
    ): MixMaterialLimitsState {
      const [mixMaterialLimitSetId, mixMaterialLimits] = action.payload;
      return {
        mixMaterialLimitSetId,
        mixMaterialLimits,
        slug: JSON.stringify(mixMaterialLimits),
      };
    },
    add(state, action: PayloadAction<CreateReduxMixMaterialLimit>) {
      const id = Math.max(...typeSafeObjectKeys(state.mixMaterialLimits)) + 1;
      return {
        ...state,
        mixMaterialLimits: {
          ...state.mixMaterialLimits,
          [id as MixMaterialLimitId]: {
            ...action.payload,
            id: id as MixMaterialLimitId,
          },
        },
      };
    },
    edit(state, action: PayloadAction<ReduxMixMaterialLimit>) {
      return {
        ...state,
        mixMaterialLimits: {
          ...state.mixMaterialLimits,
          [action.payload.id]: {
            ...action.payload,
          },
        },
      };
    },
    remove(state, action: PayloadAction<MixMaterialLimitId>) {
      delete state.mixMaterialLimits[action.payload];
    },
  },
});

export const { ...mixMaterialActions } = mixMaterialLimitsSlice.actions;
