import React from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useIsAdmin } from "hooks/settings";
import { useState } from "react";
import { MixMaterialLimitId } from "contexts/search/context";
import { ReduxMixMaterialLimit } from "src/store/slices/mixMaterialLimits";
import { MaterialLimitChip } from "components/common/chips/materialLimit";
import {
  useChefGroupMixMaterialLimits,
  useMixMaterialLimitsSlice,
} from "src/store/hooks/mixMaterialLimits";
import { useChefGroupBasketMaterialLimits } from "src/store/hooks/chefGroupBasketMaterialLimits";
import { useTenantTranslation } from "hooks/formatters";
import { typeSafeObjectEntries } from "src/utils/typeSafeObjectEntries";
import { typeSafeObjectFromEntries } from "src/utils/typeSafeObjectFromEntries";
import {
  MaterialLimit,
  MaterialLimitsEditor,
} from "components/common/forms/MaterialLimitsEditor";

/**
 * Shows each of a series of chemistry constraints in small panels.
 */

type Props = {
  chefGroupId: number;
};

type EditorDialogProps = {
  doClose: () => void;
  title: string;
  onSubmit: (materialLimit: MaterialLimit) => void;
};

type EditorDialogDeleteProps = {
  materialLimit: MaterialLimit;
  onDelete: () => void;
} & EditorDialogProps;

const emptyMaterialLimit: MaterialLimit = {
  name: "",
  baskets: {},
  hardness: 1,
  coefficients: {},
  mix: [null, null, null, null],
};

const EditorDialog = ({
  doClose,
  onSubmit,
  title,
  ...props
}: EditorDialogProps | EditorDialogDeleteProps) => {
  const { t } = useTenantTranslation();
  const [materialLimit, setMaterialLimit] = React.useState<MaterialLimit>(
    () => {
      if ("materialLimit" in props) {
        return props.materialLimit;
      } else {
        return emptyMaterialLimit;
      }
    }
  );

  const canSubmit =
    materialLimit.name !== "" &&
    Object.values(materialLimit.coefficients).length > 0;

  return (
    <Dialog open onClose={doClose} maxWidth="xl">
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <MaterialLimitsEditor
          onChange={setMaterialLimit}
          materialLimit={materialLimit}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={doClose} color="secondary">
          {t("cancel")}
        </Button>
        {"onDelete" in props ? (
          <Button
            variant="contained"
            onClick={() => {
              props.onDelete();
              doClose();
            }}
            color="warning"
          >
            {t("delete")}
          </Button>
        ) : null}
        <Button
          variant="contained"
          onClick={() => {
            if (canSubmit) {
              onSubmit(materialLimit);
              doClose();
            }
          }}
          disabled={!canSubmit}
        >
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const MixMaterialLimits = ({ chefGroupId }: Props) => {
  const mixMaterialLimits = useChefGroupMixMaterialLimits(chefGroupId);
  const chefGroupBasketMaterialLimits =
    useChefGroupBasketMaterialLimits(chefGroupId);

  const isAdmin = useIsAdmin();
  const { t } = useTenantTranslation();

  const { edit, remove } = useMixMaterialLimitsSlice();

  const [inEdit, setInEdit] = React.useState<null | ReduxMixMaterialLimit>(
    null
  );

  const handleSubmit = React.useCallback(
    (
      id: MixMaterialLimitId,
      coefficientsSignature: string,
      materialLimit: MaterialLimit
    ) => {
      const {
        name,
        hardness,
        mix: [min_mass, soft_min_mass, soft_max_mass, max_mass],
        coefficients,
      } = materialLimit;
      edit({
        id,
        chef_group_id: chefGroupId,
        min_mass,
        max_mass,
        soft_min_mass,
        soft_max_mass,
        hardness,
        coefficients: typeSafeObjectEntries(coefficients).map(
          ([material_constraint_class_id, coefficient]) => ({
            material_constraint_class_id,
            coefficient,
          })
        ),
        name,
        coefficients_signature: coefficientsSignature,
      });
    },
    [edit, chefGroupId]
  );

  const handleDelete = React.useCallback(() => {
    if (inEdit) {
      remove(inEdit.id);
    }
  }, [remove, inEdit]);

  const handleOnClick = React.useCallback(
    (mixMaterialLimit: ReduxMixMaterialLimit) => {
      setInEdit(mixMaterialLimit);
    },
    [setInEdit, chefGroupBasketMaterialLimits]
  );

  return (
    <Box>
      {Object.values(mixMaterialLimits).map((mixMaterialLimit) => (
        <MaterialLimitChip
          key={mixMaterialLimit.id}
          minMass={mixMaterialLimit.min_mass}
          maxMass={mixMaterialLimit.max_mass}
          name={mixMaterialLimit.name}
          onClick={() => handleOnClick(mixMaterialLimit)}
        />
      ))}
      {inEdit ? (
        <EditorDialog
          onSubmit={(materialLimit) =>
            handleSubmit(
              inEdit.id,
              inEdit.coefficients_signature,
              materialLimit
            )
          }
          materialLimit={{
            name: inEdit.name,
            mix: [
              inEdit.min_mass,
              inEdit.soft_min_mass,
              inEdit.soft_max_mass,
              inEdit.max_mass,
            ],
            baskets: {},
            hardness: inEdit.hardness,
            coefficients: typeSafeObjectFromEntries(
              inEdit.coefficients.map(
                ({ material_constraint_class_id, coefficient }) => [
                  material_constraint_class_id,
                  coefficient,
                ]
              )
            ),
          }}
          onDelete={() => handleDelete()}
          doClose={() => setInEdit(null)}
          title={t("editMixMaterialLimit")}
        />
      ) : null}
      {isAdmin ? <AddMixMaterialLimit chefGroupId={chefGroupId} /> : null}
    </Box>
  );
};

type AddMixMaterialLimitProps = {
  chefGroupId: number;
};

const AddMixMaterialLimit = ({ chefGroupId }: AddMixMaterialLimitProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTenantTranslation();
  const { add } = useMixMaterialLimitsSlice();

  const handleSubmit = React.useCallback(
    (materialLimit: MaterialLimit) => {
      const {
        mix: [min_mass, soft_min_mass, soft_max_mass, max_mass],
        coefficients,
        name,
        hardness,
      } = materialLimit;
      add({
        name,
        max_mass,
        min_mass,
        soft_max_mass,
        soft_min_mass,
        coefficients: typeSafeObjectEntries(coefficients).map(
          ([material_constraint_class_id, coefficient]) => ({
            material_constraint_class_id,
            coefficient,
          })
        ),
        hardness,
        chef_group_id: chefGroupId,
      });
    },
    [add, chefGroupId]
  );

  return (
    <>
      <Chip
        sx={{ mr: 1, mb: 1 }}
        label="+"
        size="small"
        color="primary"
        onClick={() => setOpen(true)}
      />

      {open ? (
        <EditorDialog
          doClose={() => setOpen(false)}
          onSubmit={handleSubmit}
          title={t("createMixMaterialLimit")}
        />
      ) : null}
    </>
  );
};
